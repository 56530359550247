.wheel_ofprizes{
	.wheel__inner {
		margin: auto;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		.gTLZXx{
			display: none;
		}
		
		.wheel__inner-wheel{
			position: relative;

			.wheel__inner-marker {
				font-size: 100px;
				transform: rotate(45deg);
				top: 35px;
				right: 30px;
				position: absolute;
				z-index: 10;
			}
		}
		.wheel__inner-wheelBordered{
			padding: 5px;
		}
		.wheel__inner-wheelNovibet{
			width: 35px;
			height: 35px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		
	}
	.wheel_ofprizes-lost{
		margin: auto;
		text-align: center;
		background: RGBA(var(--app-secondary-rgb) / 40%);
		-webkit-backdrop-filter: blur(14px);
		backdrop-filter: blur(14px);
		border-radius: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px;
		width: 100%;
		
		.wheel__ofprizes-lostBanner{
			width: auto;
			background-color: var(--app-danger);
			border-radius: 50px;
			display: flex;
			flex-direction: row;
			
			.wheel__ofprizes-lostBannerLeft{
				width: 60px;
				height: 60px;
				border-radius: 50%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border: 4px solid #fff;
				font-size: 35px;
			}
			.wheel__ofprizes-lostBannerRight {
				text-align: center;
				font-size: 35px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
				flex-basis: 80%;
				color: #fff;
				font-weight: bold;
				padding: 0px 15px;
			}
		}
		
		.wheel_ofprizes-lostAnimation {
			margin: auto;
		}
	}
	.wheel_ofprizes-won {
		margin: auto;
		text-align: center;
		background: RGBA(var(--app-secondary-rgb) / 40%);
		-webkit-backdrop-filter: blur(14px);
		backdrop-filter: blur(14px);
		border-radius: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px;
		width: 100%;
		position: relative;

		.wheel__ofprizes-wonConfetti{
			width: 100%;
			height: 100%;
			
			.wheel__ofprizes-wonConfettiA{
				position: absolute;
				top: 0;
				left: 0;
				z-index: 0;
			}
			
		}
		.wheel__ofprizes-wonContent{
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 10;
		}

		.wheel__ofprizes-chestPrize{
			margin: auto;
			max-width: 90%;
			max-height: 400px;
			margin-top: 20px;
		}
		
		.wheel__ofprizes-wonBanner {
			width: auto;
			background-color: #F35E01;
			border-radius: 50px;
			display: flex;
			flex-direction: row;
			
			.wheel__ofprizes-wonBannerLeft {
				width: 60px;
				height: 60px;
				border-radius: 50%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border: 4px solid #fff;
				font-size: 35px;
			}
			
			.wheel__ofprizes-wonBannerRight {
				text-align: center;
				font-size: 35px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
				flex-basis: 80%;
				color: #fff;
				font-weight: bold;
				padding: 0px 15px;
			}
		}
		
		.wheel_ofprizes-wonAnimation {
			margin: auto;
		}
	}
}
